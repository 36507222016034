@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap');

body{
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  min-height: 100vh;
  width: 100%;
  background: #eaedf0;
}

#root{
  max-width: 1000px;
  width: auto;
  margin: auto;
  padding: 20px;
}
