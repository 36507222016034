.search{
  background: white;
  padding: 14px 22px;
  width: 100%;
  max-width: 500px;
  margin: auto;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  outline: none;
  box-shadow: -5px -5px 15px #ffffff99, 5px 5px 15px #152b4711;
  border: none;
  font-weight: 500;
  border-radius: 6px;
  transition: box-shadow 0.3s, border-color 0.3s, background 0.3s;
  cursor: pointer;
  box-sizing: border-box;
}

.search::placeholder{
  color: #aaa;
}

.search:hover{
  background: #f6f6f6;
}

.search:focus{
  box-shadow: -3px -3px 6px #ffffff66, 3px 3px 6px #152b470c, 0 0 0 3px #0077ff49;
  border-color: transparent;
  cursor: text;
}
