@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap);
.header{
  text-align: center;
  margin: 30px 0px;
  line-height: 1.4em;
}

.search{
  background: white;
  padding: 14px 22px;
  width: 100%;
  max-width: 500px;
  margin: auto;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  outline: none;
  box-shadow: -5px -5px 15px #ffffff99, 5px 5px 15px #152b4711;
  border: none;
  font-weight: 500;
  border-radius: 6px;
  transition: box-shadow 0.3s, border-color 0.3s, background 0.3s;
  cursor: pointer;
  box-sizing: border-box;
}

.search::-webkit-input-placeholder{
  color: #aaa;
}

.search:-ms-input-placeholder{
  color: #aaa;
}

.search::-ms-input-placeholder{
  color: #aaa;
}

.search::placeholder{
  color: #aaa;
}

.search:hover{
  background: #f6f6f6;
}

.search:focus{
  box-shadow: -3px -3px 6px #ffffff66, 3px 3px 6px #152b470c, 0 0 0 3px #0077ff49;
  border-color: transparent;
  cursor: text;
}

.item{
  position: relative;
  width: 180px;
  height: 180px;
  background: white;
  margin: 15px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: -5px -5px 15px #ffffff99, 5px 5px 15px #152b4711;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  box-sizing: border-box;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.item:hover{
  box-shadow: -5px -5px 15px #ffffff99, 5px 5px 15px #152b4711, 0 0 0 5px #0077ff49;
}

.item-copy{
  position: absolute;
  background: #0077ff33;
  color: #0077ff;
  font-weight: 700;
  border-radius: 6px;
  font-size: 0.8em;
  padding: 6px;
  bottom: 8px;
  left: 8px;
  transition: opacity 0.3s;
  cursor: pointer;
}

.label-true{
  opacity: 1;
}

.label-false{
  opacity: 0;
}

.item-emoji{
  font-size: 2.4em;
  cursor: pointer;
}

.item-name{
  font-size: 0.9em;
  line-height: 1.4em;
  width: 150px;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .item{
    height: 120px;
    width: 130px;
  }
  .item-emoji{
    font-size: 1.6em;
  }
  .item-name{
    font-size: 0.8em;
    margin: 0px 20px;
    padding: 0px 20px;
    box-sizing: border-box;
  }
}

.results{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

.container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
}

body{
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  min-height: 100vh;
  width: 100%;
  background: #eaedf0;
}

#root{
  max-width: 1000px;
  width: auto;
  margin: auto;
  padding: 20px;
}

